import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LoginOutlined, EyeInvisibleOutlined, EyeTwoTone, ExclamationCircleFilled, FacebookOutlined, TwitterOutlined, DoubleRightOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import AxiosInstance from "../services/axiosInstance";
import { Toaster } from "../utility/Toaster";
import { Layout, Button, Typography, Form, Input, Modal, Select,Radio } from "antd";
import Video from "../utility/Video";
import logo from "./../assets/images/logo.png";
import axios from "axios";
const { Title } = Typography;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;
const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    companyId: "",
    clientId:"",
  });
  const [AuthOb, setAuthOb] = useState({});
  const [SlectedCid, SetSlectedCid] = useState('');
  const [SlectedClientId, SetClientId] = useState('');
  const [configData, setConfigData] = useState({ sms: false, email: false });
  const [backgroundColor, setBackgroundColor] = useState('#C7C5F4');
  const history = useHistory();
  const [errorsForget, setForgotPassErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: '', // to store email
    contactNumber: '', // to store contact number
    type: 'email' // default type
  });
  const [forgotPasswordForm] = Form.useForm(); // Initialize the form instance
  const changeBackgroundColor = () => {
    // const randomNumber = Math.random();
    // const digitsAfterDecimal = parseFloat(randomNumber.toFixed(2).split('.')[1]);
    // console.log(digitsAfterDecimal); // Output the digit just after the decimal point
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16); // Generate random color
    // const randomColor = `#C5CF${digitsAfterDecimal}`;
    // const a = `linear-gradient(90deg, #C7C5F4, ${randomColor})`
    setBackgroundColor(randomColor); // Set the background color
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevstate => ({ ...prevstate, [name]: value }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, you can submit or process the data here
      onFinish(formData);
      // setErrors({});
    } else {
      // Form is not valid, display error messages
    }
  };

 
  const [changePasswordForm] = Form.useForm();
  useEffect(() => {
    // Clear localStorage when component mounts
    const token = localStorage.getItem("token");
    const pin = localStorage.getItem("pin");
    if (!pin && !token) localStorage.clear();
    else history.replace("/pin");

    const interval = setInterval(changeBackgroundColor, 3000);

    return () => clearInterval(interval); // Cleanup function to clear the interval
  }, []);
  const close = () => {
    localStorage.clear();
    Modal.destroyAll();
  }


  useEffect(() => {
    const getForgotPasswordData = () => {
      axios
        .get("https://backend.revivingcare.us:3306/v1/check-sms-configuration")
        // .get("https://revivingcare.net:3304/v1/check-sms-configuration")
        .then(({ data }) => {
          setLoading(false);
          if (data.success) {
            setConfigData({ sms: data.data.sms, email: data.data.email });
            console.log(data.data,'dataddddddddddddddddddddddddddddddddddddddddddddddddd');
          } else {
            Toaster("error", data.message);
          }
        })
        .catch((err) => {
          Toaster("error", err?.message || "Something went wrong.");
          setLoading(false);
        });
    };

    getForgotPasswordData();
  }, []);


  const submitChangePasswordForm = () => {
    if (!changePasswordForm.getFieldValue('oldPassword')) {
      Toaster("error", 'Old password is missing');
      return
    }
    else if (!changePasswordForm.getFieldValue('newPassword')) {
      Toaster("error", 'New password is missing');
      return
    }
    else if (!changePasswordForm.getFieldValue('confirm')) {
      Toaster("error", 'Confirm password is missing');
      return
    }
    const payload = {
      oldPassword: changePasswordForm.getFieldValue('oldPassword'),
      newPassword: changePasswordForm.getFieldValue('newPassword'),
    };
    const endpoint = { e: 'emp/update-password', c: 'client/update-password' };
    const local = endpoint[localStorage.getItem("type") || ""];
    AxiosInstance.put(local, payload)
      .then(({ data }) => {
        if (data.success) {
          Modal.destroyAll();
          localStorage.removeItem("evvUrl");
         // window.location.reload();
         // history.replace("/pin");
          // Toaster("success", data.message, '');
        } else {
          Toaster("error", data.message, '');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const openChangePasswordModal = () => {
    setLoading(false);
    // const pwdPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/i;
    Modal.confirm({
      title: 'Create your new password so you can login to your account',
      content: <>
        <Form
          layout="vertical"
          form={changePasswordForm}
          name="register"
          onFinish={submitChangePasswordForm}
          style={{
            marginLeft: '-2rem',
            maxWidth: 600,
          }}
          scrollToFirstError
        >

          <Form.Item
            name="oldPassword"
            label="Old Password"

            rules={[
              {
                required: true,
                message: 'Please Enter your old password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password size="small" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please Enter your new password!',
              },
              // ({getFieldValue}) => ({
              //   validator(_, value) {
              //     if (!value || pwdPattern.test(value)) {
              //       return Promise.resolve();
              //     }
              //     return Promise.reject('Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.');
              //   },
              // }),
            ]}
            hasFeedback
          >
            <Input.Password size="small" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm New Password"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your new password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password size="small" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
          {/* <Form.Item>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item> */}
        </Form>
      </>,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <Button type='primary' danger className="ant-btn-modal" onClick={() => close()}>Close</Button>
          <Button className="ant-btn-modal" type='primary' onClick={() => submitChangePasswordForm()}>Okay</Button>
          {/* <OkBtn /> */}
        </>
      ),
    });
  }

  const onFinish = (values) => {
    setLoading(true);
    const loginPayload = {
      email: values.email,
      password: values.password,
      appType: "web"
    };

    if (SlectedCid !== "") {
      loginPayload.companyId = SlectedCid;
    }
    if (SlectedClientId !== "") {
      loginPayload.clientId = SlectedClientId;
    }
    AxiosInstance.post("evv-login", loginPayload)
      .then(({ data }) => {
        if (data.success) {
          if (data.message == 'You are registered with multiple companies. Please select a company to login') {
            const array = data.data
            showCompaniesPrompt(values, array);
            return false;
          }
          if (data.message == 'You are registered with multiple clients. Please select a client to login') {
            const array = data.data
            showRPSPrompt(values, array);
            return false;
          }
          const { data: userData } = data;
          const token = userData.token;
          if (userData.type === "r") {
            userData.type = "c";
            userData.rType = "rp";
          }
          localStorage.setItem("token", token);
          localStorage.setItem("evvUrl", data.evvUrl);
          localStorage.setItem("chatUrl", data.chatUrl);
          localStorage.setItem("dirtyBit", data.dirtyBit);
          localStorage.setItem('type', userData.type);
          localStorage.setItem("USER_INFO", JSON.stringify(userData));
          if (userData.rType)
            localStorage.setItem('rType', userData.rType);
          localStorage.setItem("state", userData.companyId.state);
          if (userData?.configurationEvvNew) {
            const filteredMenu = userData.configurationEvvNew.menu.filter((x => x.value === true));
            localStorage.setItem("Menus", JSON.stringify(filteredMenu));
            localStorage.setItem("Home_Screen", JSON.stringify(userData.configurationEvvNew.conditional.home));
            localStorage.setItem("History_Screen", JSON.stringify(userData.configurationEvvNew.conditional.history));
            localStorage.setItem("payrolGracePeriod", JSON.stringify(userData.configurationEvvNew?.payrolGracePeriod) || 0);
            localStorage.setItem("startEndTimeRestriction", JSON.stringify(userData.configurationEvvNew?.startEndTimeRestriction) || false);
            localStorage.setItem("isAllowedPayrollGracePeriod", JSON.stringify(userData.configurationEvvNew?.isAllowedPayrollGracePeriod) || false);
            window.dispatchEvent(new Event("storage"));
          }
          setAuthToken(token);
          // Toaster("success", data.message, '');
          if (userData.firstLogin && userData?.rType !== "rp") {
            openChangePasswordModal();
           return;
         }
         else history.push("/pin");
        } else {
          console.log(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        // Toaster('error', 'Error', err.response.data.message);
        setLoading(false);
      });
  };



  useEffect(() => {
    if (SlectedCid) {
      onFinish(AuthOb)
    }
    Modal.destroyAll();
  }, [SlectedCid]); // Dependency array

  useEffect(() => {
    if (SlectedClientId) {
      onFinish(AuthOb)
    }
    Modal.destroyAll();
  }, [SlectedClientId]); // Dependency array

   // Sync forgotPasswordData with form values using useEffect
   useEffect(() => {
    const values = forgotPasswordForm.getFieldsValue();
    setForgotPasswordData({
      email: values.email || "",
      contactNumber: values.phone || "",
      type: values.method || "email", // Default to email if method is not set
    });
  }, [forgotPasswordForm]); // Dependency ensures it runs when form instance changes

  const handleChangeCompany = (value, field) => {
    if (!value) return;
    SetSlectedCid(value)
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChangeRP = (value, field) => {
    if (!value) return;
    SetClientId(value)
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };


  const submitForgotPassword = (email,contactNumber,type) => {
     // Email and Phone Validation
  if (type === "email") {
    if (!email || !/\S+@\S+\.\S+/.test(email.trim())) {
      Toaster("error", "Please enter a valid email address.");
      setLoading(false);
      return;
    }
  }

  if (type === "sms") {
    if (!contactNumber || !/^[0-9]{10}$/.test(contactNumber.trim())) {
      Toaster("error", "Please enter a valid 10-digit phone number.");
      setLoading(false);
      return;
    }
  }
        setLoading(true);
  
    // Prepare the payload
    const loginPayload = {
      field: type === "email" ? email.trim() : contactNumber.trim(),
      type,
    };
  
    // Call the API
    axios
      .post("https://backend.revivingcare.us:3306/v1/evv-forgot-password", loginPayload)
      // .post("https://revivingcare.net:3304/v1/evv-forgot-password", loginPayload)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          Toaster("success", data.message);
          setForgotPasswordData({
            email: "",
            contactNumber: "",
            type: "email",
          });
          Modal.destroyAll(); // Close the modal
          forgotPasswordForm.resetFields(); 
        } else {
          Toaster("error", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", err?.message || "Something went wrong.");
        setLoading(false);
      });
  };
  
   const onCloseForgotPassword = () => {
    // modal.destroy(); // Close the modal
    setForgotPasswordData({
      email:  "",
      contactNumber:  "",
      type: 'email',
    });
     // Reset form fields
  forgotPasswordForm.resetFields(); // Clear all form fields and reset to initial values
   }
  

   const onOpenForgotPassword = () => {
    const modal = Modal.confirm({
      title: "Password Recovery",
      content: (
        <Form
          layout="vertical"
          form={forgotPasswordForm}
          initialValues={{ method: configData.email ? "email" : "sms" }} // Set default method dynamically
          onFinish={(values) => {
            setForgotPasswordData({
              email: values.email || "",
              contactNumber: values.phone || "",
              type: values.method,
            });
            submitForgotPassword(values.email, values.phone, values.method); // Call the API
            modal.destroy(); // Close modal after submission
          }}
          onValuesChange={(changedValues) => {
            if (changedValues.method) {
              setForgotPasswordData((prevState) => ({
                ...prevState,
                type: changedValues.method,
                email: "",
                contactNumber: "",
              }));
              forgotPasswordForm.resetFields(["email", "phone"]);
            }
          }}
        >
          {/* Recovery Method Selection */}
          <Form.Item name="method" rules={[{ required: true }]}>
            <Radio.Group>
              {configData.email && <Radio value="email">Email</Radio>}
              {configData.sms && <Radio value="sms">SMS</Radio>}
            </Radio.Group>
          </Form.Item>
  
          {/* Email Input */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.method !== currentValues.method
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("method") === "email" && (
                <Form.Item
                  label="Enter your registered EVV email"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your registered email!" },
                    { type: "email", message: "Please enter a valid email address!" },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              )
            }
          </Form.Item>
  
          {/* Phone Input */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.method !== currentValues.method
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("method") === "sms" && (
                <Form.Item
                  label="Enter your registered phone number"
                  name="phone"
                  rules={[
                    { required: true, message: "Please enter your registered phone number!" },
                    { pattern: /^[0-9]{10}$/, message: "Please enter a valid 10-digit phone number!" },
                  ]}
                >
                  <Input placeholder="Phone Number" maxLength={10} />
                </Form.Item>
              )
            }
          </Form.Item>
        </Form>
      ),
      footer: (
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button
            onClick={() => {
              onCloseForgotPassword();
              modal.destroy();
            }}
            danger
            size="small"
            type="primary"
          >
            Close
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              forgotPasswordForm
                .validateFields()
                .then(() => {
                  forgotPasswordForm.submit(); // Trigger the form's onFinish
                })
                .catch((error) => {
                  console.log("Validation failed:", error);
                });
            }}
          >
            Submit
          </Button>
        </div>
      ),
    });
  };
  
  
  

  
  

  const showCompaniesPrompt = (obj, CompanyList) => {
    setAuthOb(obj)
    confirm({
      title: 'Select the company to proceed',
      icon: <ExclamationCircleFilled />,
      footer: null,
      content: (
        <div>
          <Select
            placeholder="Select a company"
            style={{ width: "100%", marginLeft: "-10px" }}
            onChange={(value) => handleChangeCompany(value, "companyId")}
          >
            {CompanyList.map((option) => (
              <Option key={option._id} value={option.companyId._id}>
                {option.companyId.sName}
              </Option>
            ))}
          </Select>
        </div>
      ),
    });
  };



  const showRPSPrompt = (obj, ClientList) => {
    setAuthOb(obj)
    debugger
    confirm({
      title: 'Select a Client to proceed',
      icon: <ExclamationCircleFilled />,
      footer: null,
      content: (
        <div>
          <Select
            placeholder="Select a Client"
            style={{ width: "100%", marginLeft: "-10px" }}
            onChange={(value) => handleChangeRP(value, "clientId")}
          >
            {ClientList.map((option) => (
              <Option key={option._id} value={option.client_id._id}>
                {option.client_id?.fullname}
              </Option>
            ))}
          </Select>
        </div>
      ),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const setAuthToken = (token) => {
    if (token) {
      AxiosInstance.defaults.headers.common["Token"] = token;
    } else delete AxiosInstance.defaults.headers.common["Token"];
  };

  return (
    <>
      <Video />
      <Layout
        className="layout-default layout-signin"
        style={{ position: "relative" }}
      >
        <Content className="signin text-center">
          <img
            alt="logo"
            className="center"
            style={{ width: "160px" }}
            src={logo}
          />
          <Title style={{ margin: 0, textAlign: "center" }} underline level={2}>
            RevivingCare
          </Title>
          <Title
            style={{ marginTop: 4, textAlign: "center", opacity: 0.7 }}
            underline
            strong
            level={4}
          >
            Reimagined Homecare Software
          </Title>

          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Form.Item
              className="username"
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "The email is not valid Email!",
                },
                {
                  required: true,
                  message: "Please enter your Email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              style={configData.email || configData.sms ? { marginBottom: "0px" } : {}}
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password placeholder="Password" className="sign-input" />
            </Form.Item>
            {/* Forgot Password button */}
            {(configData.email || configData.sms) && (
              <Form.Item>
                <Button
                  type="link"
                  className="ant-edit-link"
                  style={{ float: "right", fontSize: "12px", padding: 0 }}
                  onClick={onOpenForgotPassword}
                >
                  Forgot Password?
                </Button>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                icon={<LoginOutlined />}
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>

      {/* <div className="sign_container" style={{ backgroundColor }}>
        <div className="sign_screen">
          <div className="screen__content">
            <form className="login" onSubmit={handleSubmit}>
              <div className="login__field">
                <i className="login__icon"><UserOutlined /></i>
                <input type="text" name="email" value={formData.email} onChange={handleInputChange} className="login__input" placeholder="Email" />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="login__field">
                <i className="login__icon"><LockOutlined /></i>
                <input type="password" name="password" value={formData.password} onChange={handleInputChange} className="login__input" placeholder="Password" />
                {errors.password && <div className="error">{errors.password}</div>}
              </div>
              <button type="submit" className="button login__submit">
                <span className="button__text">{!loading ? 'Log In Now' : 'Loading ...'}</span>
                <i className="button__icon"><DoubleRightOutlined /></i>
              </button>
            </form>
            <div className="social-login">
              <h3 style={{marginTop:'5px',color:'#fff'}}>Connect via</h3>
              <div className="social-icons">
                <a href="#" className="social-login__icon"> <InstagramOutlined /></a>
                <a href="#" className="social-login__icon"><FacebookOutlined /></a>
                <a href="#" className="social-login__icon"><TwitterOutlined /></a>
              </div>
            </div>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default SignIn;
